import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import colors from "../../styles/colors"

const RecipeLink = styled(Link)`
  text-decoration: none;
`

const Title = styled.h3`
  color: ${colors.white};
  font-size: calc((1.2 - 1) * 1.2vw + 1rem);
  line-height: 1.2em;
  text-align: center;
  margin-top: 17px;
  margin-bottom: 2px;
`

const Category = styled(Link)`
  color: ${colors.lightGrey};
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  opacity: 0.7;
  font-size: 13px;
  line-height: 1.4em;
  margin-bottom: 2px;
  display: block;
`

const RecipeCardContainer = styled.div`
  padding: 25px;
  width: 100%;
`

const RecipeCard = (props) => {
  const { category, title, gatsbyImageData, imageDescription, slug } = props
  return (
    <RecipeCardContainer>
      <RecipeLink to={`/content-recipes/${slug}`}>
        <GatsbyImage image={gatsbyImageData} alt={imageDescription} />
        <Title>{title}</Title>
      </RecipeLink>
      <Category to={`/content-recipes/category/${category.slug}`}>
        {category.title}
      </Category>
    </RecipeCardContainer>
  )
}

export default RecipeCard
