import React from "react"
import styled from "styled-components"
import RecipeCard from "./RecipeCard"
import Masonry from "react-masonry-css"

const RecipeSection = styled.section`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
`

const FeaturedRecipes = (props) => {
  const recipes = props.recipes.sort((a, b) => {
    return a.node.position - b.node.position
  })

  return (
    <RecipeSection
      as={Masonry}
      breakpointCols={{ default: 4, 1400: 3, 992: 2, 576: 1 }}
      className="masonry-grid"
    >
      {recipes.map((recipe, index) => {
        const { category, title, image, slug } = recipe.node
        return (
          <RecipeCard
            key={index}
            category={category}
            title={title}
            slug={slug}
            gatsbyImageData={image.gatsbyImageData}
            imageDescription={image.description}
          />
        )
      })}
    </RecipeSection>
  )
}

export default FeaturedRecipes
