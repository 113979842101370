import React, { useState } from "react"
import Layout, {
  DESKTOP_HEADER_HEIGHT,
  MOBILE_HEADER_HEIGHT,
} from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql, Link } from "gatsby"
import styled from "styled-components"
import FeaturedRecipes from "../components/Recipe/FeaturedRecipes"
import fonts from "../styles/fonts"
import colors from "../styles/colors"
import breakpoints from "../styles/breakpoints"
import { navigate } from "gatsby"
import { Row, Col } from "styled-bootstrap-grid"

const Icon = styled.img`
  height: 100px;
`



const Description = styled.h4`

  font-size: calc(0.6vw + 16px);
  
  line-height: 142%;
font-weight: 400;
  width: 60%;
  margin: 0 auto;
  color: white;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 2rem;
`

const CICol = styled(Col)`
  padding-right: 20px;
  padding-left: 20px;
  @media (max-width: ${breakpoints.md}) {
    padding-top: 40px;
  }
`

const CIDiv = styled.div`
  padding-bottom: 75px;
  padding-top: 75px;
`

const ColDiv = styled.div`
  display: flex;
  justify-content: center;
`
const Search = styled.input`
  margin: auto;
  display: flex;
  height: 40px;
  border-radius: 20px;
  text-align: left;
  padding: 22px;
  width: 40%;
  font-size: calc(.8vw + .8rem);
  padding-left: 40px;
  @media (max-width: ${breakpoints.md})
  { 
    width: 80%;
    max-width: 1280px;
  }
  @media (min-width: 1600px)
  {
    font-size: 26px;
  }
`

const Heading = styled.h2`
  ${fonts.workSansBold}
  line-height: 1.104;
  margin: 2rem 0;
  font-size: calc(2.4vw + 16px);
  font-weight: 900;
  @media (min-width: 1600px) {
    font-size: 55px;
  }
  color: ${colors.yellow};
  text-align: center;
`
const Content = styled.p`
  color: ${colors.lightGrey};
  line-height: 1.6;
  text-align: center;
  padding-bottom: 17px;
`

const ContentContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-left: 5vw;
  padding-right: 5vw;
  padding-bottom: 6.6vmax;
`

const WhiteBox = styled.div`
  width: 80%;
  height: 100%;
  background-color: white;
  margin: 0 auto;
  padding: 20px;
`
const BlockHeading = styled.h3`
  margin: 0 auto;
  text-align: center;
  color: ${colors.royalBlue};
  padding-bottom: 10px;
  font-size: calc(1.6vw + 1rem);
  @media (min-width: 1600px) {
    font-size: calc(25px + 1rem);
  }
`

const BlockSubHeading = styled.h4`
  margin: 0 auto;
  text-align: center;
  color: black;
  line-height: 121%;
  padding-top: 20px;
  ${'' /* font-size: calc(<div className="8"></div>vw + 1rem); */}
  @media (min-width: 1600px)
  {
    font-size: calc(13px + 1 rem;) 
  }

`

const BlockDescription = styled.p`
  margin: 0 auto;
  text-align: center;
  color: black;
  padding-top: 20px;

  @media (max-width: ${breakpoints.md}) {
    padding-top: 10px;
    padding-bottom: 30px;
  }
`
const ContentContainer2 = styled.div`
  background: ${(props) => "url(" + props.background + ")" ?? "white"};
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  ${"" /* max-width: 1600px; */}
  height: 100%;
  margin: 0 auto;
  display: inline-block;
  @media (max-width: ${breakpoints.md})
  {
    background-size: cover; 
  }
`

const Links = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding-top: 17px;
  flex-wrap: wrap;
`

const InternalLink = styled(Link)`
  display: block;

  width: calc(33% - 34px);
  padding-left: 17px;
  padding-right: 17px;
  color: ${colors.white};
  margin-bottom: 1rem;
  font-size: calc((1 - 1) * 1.2vw + 1rem);
  text-underline-offset: 0.2em;
  text-align: center;
  line-height: 1.6;

  @media (max-width: ${breakpoints.md}) {
    width: calc(100% - 34px);
  }
`

const PageContainer = styled.div`
  padding-top: calc(4vmax + ${DESKTOP_HEADER_HEIGHT}px);

  @media (max-width: ${breakpoints.md}) {
    padding-top: calc(4vmax + ${MOBILE_HEADER_HEIGHT}px);
  }

  i {
            position: absolute;
            left: 32%;
            padding-top: 10px;
            padding-bottom: 5px;
            padding-left: 15px;
            min-width: 40px;
            ${'' /* @media (min-width: 1600px)
            {
              left: 368px;
            } */}
            @media (max-width: ${breakpoints.md})
            {
              left: 13.5%;
            }
        }
`

const AllCookingInstructions = (props) => {
  // const { metaTitle, metaDescription, landingPageHeading, landingPageText, socialMediaImage } =
  //   props.data.contentfulRecipeTag

  const metaTitle = "Cooking Instructions"
  const metaDescription = "Cooking Instructions"
  const [cookValue, setCookvalue] = useState("")

  const recipes = props.data.allContentfulRecipePage.edges
  const path = props.location.pathname
  var cookingInstructions = props.data.allContentfulCookingInstructions.edges
  cookingInstructions = cookingInstructions.filter(
    (x) => new Date(x.node.updatedAt).getTime() >= Date.now() - 31557600000
  ) // 1 year ago
  const slugsAndTitles = cookingInstructions.map((x) => {
    return {
      slug: x.node.slug,
      title: x.node.title,
      updatedAt: x.node.updatedAt,
    }
  })

  let block = props.data.contentfulCookingInstructionsPageBlock

  let sharingImage = false
  const displayLinks = () => {
    let searchInput = document.querySelector("#Search")
    let searchValue = searchInput?.value
    let results = slugsAndTitles
      .filter((x) => x.title.toUpperCase().includes(searchValue.toUpperCase()))
      .map((y) => y.title)
    for (const meal of slugsAndTitles) {
      if (results.includes(meal.title) || !searchValue) {
        try {
          document.querySelector("#" + meal.slug).style.display = "block"
        } catch (e) {
          console.log(e)
        }
      } else {
        try {
          document.querySelector("#" + meal.slug).style.display = "none"
        } catch (e) {
          console.log(e)
        }
      }
    }
  }

  // if (socialMediaImage) {
  //   sharingImage = socialMediaImage.file.url
  // }

  function nav(path) {
    navigate("../" + path)
  }

  function onInput() {
    var val = document.getElementById("Search")?.value
    var opts = document.getElementById("cooking-list")?.childNodes

    if (opts) {
      for (var i = 0; i < opts.length; i++) {
        if (opts[i].value === val) {
          // An item was selected from the list!
          // yourCallbackHere()
          if (opts[i] && opts[i].dataset && opts[i].dataset.value) {
            let slug
            if (
              [
                "woon-chewynoodles",
                "bridgetown-roti-stew",
                "red-bread-lemon-crisp",
                "cecis-parmigiana"

              
              ].includes(opts[i].dataset.value)
            ) {
              switch (opts[i].dataset.value) {
                case "woon-chewynoodles":
                  slug = "woon-noodles";
                  break
                case "bridgetown-roti-stew":
                  slug = "bridgetown-stew"
                  break
                case "red-bread-lemon-crisp":
                  slug = "redbread-crisp"
                  break
                case "cecis-parmigiana":
                  slug = "cecis-parm"
                  break
              }
            }
            if (slug) {
              navigate("../" + slug)
            } else {
              navigate("../" + opts[i]?.dataset.value)
            }
            break
          }
        }
      }
    }
  }

  return (
    <Layout bg={colors.royalBlue}>
      <Seo
        title={metaTitle}
        description={metaDescription}
        path={path}
        image={sharingImage}
      />
      <PageContainer>
        <ContentContainer>
          <Heading>Cooking Instructions</Heading>
          {/* <Search id={'Search'} placeholder={`Search`} onChange={displayLinks}/> */}
          <Description>Find the cooking instructions, serving suggestions, and paired recipes for each dish! </Description>
          <i>🔍</i>
          <Search
            onChange={onInput}
            id={"Search"}
            placeholder={"Dish or restaurant name"}
            list={"cooking-list"}/>
          <datalist style={{ backgroundColor: "white" }} id={"cooking-list"}>
            {cookingInstructions.map((instruction, index) => {
              return (
                <option data-value={instruction.node.slug}>
                  {instruction.node.title}
                </option>
              )
            })}
          </datalist>
        </ContentContainer>
        <ContentContainer2 background={block.background.file.url}>
          <CIDiv>
            <WhiteBox>
              <BlockHeading>{block.headline}</BlockHeading>
              <Row style={{ paddingTop: "20px" }}>
                <CICol md={4}>
                  <ColDiv>
                    <Icon src={block.image1.file.url}></Icon>
                  </ColDiv>
                  <ColDiv>
                    <BlockSubHeading>{block.subHeadline1}</BlockSubHeading>
                  </ColDiv>
                  <ColDiv>
                    <BlockDescription>{block.description1}</BlockDescription>
                  </ColDiv>
                </CICol>

                <CICol md={4}>
                  <ColDiv>
                    <Icon src={block.image2.file.url}></Icon>
                  </ColDiv>
                  <ColDiv>
                    <BlockSubHeading>{block.subHeadline2}</BlockSubHeading>
                  </ColDiv>
                  <ColDiv>
                    <BlockDescription>{block.description2}</BlockDescription>
                  </ColDiv>
                </CICol>

                <CICol md={4}>
                  <ColDiv>
                    <Icon src={block.image3.file.url}></Icon>
                  </ColDiv>
                  <ColDiv>
                    <BlockSubHeading>{block.subHeadline3}</BlockSubHeading>
                  </ColDiv>
                  <ColDiv>
                    <BlockDescription>{block.description3}</BlockDescription>
                  </ColDiv>
                </CICol>
              </Row>
            </WhiteBox>
          </CIDiv>
        </ContentContainer2>
        <Heading>Featured Recipes</Heading>
        <FeaturedRecipes recipes={recipes} />
      </PageContainer>
    </Layout>
  )
}

export default AllCookingInstructions

export const pageQuery = graphql`
  query AllCookingInstructionsTemplateQuery {
    contentfulCookingInstructionsPageBlock(slug: { eq: "cooking-block" }) {
      id
      description1
      description2
      description3
      headline
      background {
        file {
          url
        }
      }
      image1 {
        file {
          url
        }
      }
      image2 {
        file {
          url
        }
      }
      image3 {
        file {
          url
        }
      }
      subHeadline1
      subHeadline2
      subHeadline3
    }
    allContentfulCookingInstructions {
      edges {
        node {
          slug
          title
          updatedAt
        }
      }
    }
    allContentfulRecipePage {
      edges {
        node {
          id
          image {
            gatsbyImageData
            description
          }
          slug
          category {
            title
            slug
          }
          title
          position
        }
      }
    }
  }
`
